import {
    FlatList,
    Platform, StyleSheet, Text, Image,
    View, Animated, LayoutAnimation, UIManager,
    ActivityIndicator, StatusBar
} from 'react-native'
import React, {useState, useCallback} from 'react'

const Home = () => {

    return <View
        style={styles.container}
    >
        <Image
            source={
                     require('../assets/images/djdilip-1.jpg')
            }
            style={styles.welcomeImage}
        />
        <Text>    DJ Dilip - DJ and Producer </Text>
        <Text
            style={styles.text}
        >Coming soon</Text>

    </View>

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: Platform.OS === 'web' ? '100vw' : '100%',
        backgroundColor: '#fff',
        alignItems: 'center'
    },
    welcomeImage: {
        width: 801,
        height: 534,
        resizeMode: 'contain',
        marginTop: 3,
        marginLeft: -10,
    },
    text: {
        color: 'red',
        alignItems: 'center',
        padding: '40px'
    },
})

export default Home


