import React, {useState} from 'react'
import {
    Text, View, FlatList, StyleSheet, Dimensions,
    ActivityIndicator, Platform, Image
} from 'react-native'



const About = () => {

    return <View
    >
        {/*<Text>Dilip Reddy is a DJ/Electronic Musician and House music producer from New York. Although he never had musical background until his late teens,*/}
        {/*    his passion towards Djing made Dilip Reddy to learn the craft by assisting established DJ's in the city.*/}
        {/*    After being a DJ for events for an year, having inspired by Dance anthems and tunes by his favorite DJ's, made Dilip Reddy to produce music.*/}
        {/*    By playing around with midi controller and pc, he got instantly hooked up with music production.*/}
        {/*    Since then Dilip Reddy spends most of the time in Dance Music production and Music Theory.*/}
        {/*    His immense desire led Dilip Reddy to study Electronic Music Production from SAE New York.*/}
        {/*    Dilip Reddy has worked as an Engineer in SoundClash Recording studio in Manhattan, where he earned valuable experience.*/}
        {/*    Dilip Reddy's debut Progressive House track single Volcano has been released and gained support from DJ's and music lovers all over the globe.*/}
        {/*    With Dilip’s second release The Dreams, he stepped into dance music world.*/}
        {/*    With his latest release “Red Sea”, he is creating waves in the scene..*/}
        {/*</Text>*/}
<Text>
    DJ Dilip - DJ and Producer
</Text>
        <Text
            style={styles.text}
        >
            DJ Dilip is a DJ and House music producer from Los Angeles. Originally from India, his passion for DJing made him learn the craft by assisting established Bollywood and House DJs in India. His style includes House, Bollywood, and Top-40.
            In 2011, after four years of DJing events where he was inspired by his favorite dance anthems, Dilip Reddy started producing his own songs.
            His passion for music led DJ Dilip to study Electronic Music Production from SAE New York. He also has worked as a sound engineer in SoundClash Recording studio in Manhattan, where he earned valuable experience.
            Dilip Reddy's 2011 debut Progressive House track single Volcano gained support from DJs and music lovers all over the globe. With his further releases, "The Dreams" and“Red Sea,” he created waves in the scene.
        </Text>
    </View>

}



const styles = StyleSheet.create({


        text: {
            color: 'red',
            alignItems: 'center',
            padding: '40px'
        },



    }
)

// Victory Bubbles
export default About