import {Platform} from "react-native"
import {createAppContainer, createSwitchNavigator} from 'react-navigation'
import {createBrowserApp} from '@react-navigation/web'
import MainTabNavigator from './MainTabNavigator'




const createApp = Platform.select({
    web: config => createBrowserApp(config, {history: 'hash'}),
    default: config => createAppContainer(config),
})


const AppNavigator = createApp(
    createSwitchNavigator({
        MainTabNavigator
    })
)


export default AppNavigator