import React, {useState} from 'react'
import {Platform, StatusBar, StyleSheet, View} from 'react-native'
 import AppNavigator from './navigation/AppNavigator'
import {AppLoading} from 'expo'
import * as Font from 'expo-font'
import {Asset} from 'expo-asset'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import SafeAreaView from 'react-native-safe-area-view'



const loadResourcesAsync = async () => {
  await Promise.all([
    Asset.loadAsync([
      require('./assets/images/robot-dev.png'),
        require('./assets/images/djdilip-1.jpg'),

    ]),
    Font.loadAsync({
      // This is the font that we are using for our tab bar
      // ...Ionicons.font,
      // We include SpaceMono because we use it in HomeScreen.js. Feel free to
      // remove this if you are not using it in your app
      'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
    }),
  ])
}

const handleLoadingError = (error) => {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error)
}

const handleFinishLoading = (setLoadingComplete) => {
  setLoadingComplete(true)
}

const App = (props) => {
  const [isLoadingComplete, setLoadingComplete] = useState(false)

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return (
        <SafeAreaProvider>
          <SafeAreaView style={{flex: 1}}>
            <AppLoading
                startAsync={loadResourcesAsync}
                onError={handleLoadingError}
                onFinish={() => handleFinishLoading(setLoadingComplete)}
            />
          </SafeAreaView>
        </SafeAreaProvider>
    )
  } else {
    return (
        <View style={styles.container}>
          {Platform.OS === 'ios' && <StatusBar barStyle="default"/>}

            <SafeAreaProvider>
              <SafeAreaView style={{flex: 1}}>
                <AppNavigator/>
              </SafeAreaView>
            </SafeAreaProvider>
        </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: Platform.OS === 'web' ? '100vw' : '100%',
    // backgroundColor: 'black'
  },
})

export default App


// TODO expo SplashScreen