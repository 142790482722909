import React from 'react'
import {Platform} from 'react-native'

import Home from "../screens/Home"
import About from "../screens/About"

import {createBottomTabNavigator, createMaterialTopTabNavigator} from 'react-navigation-tabs'


let MainTabNavigator = createBottomTabNavigator({
    home: Home,
    about: About
},)

if (Platform.OS === "web") {
    MainTabNavigator = createMaterialTopTabNavigator({
        home: Home,
        about: About
    },)

}


MainTabNavigator.path = ''

export default MainTabNavigator






